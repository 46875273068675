import connectionIllustration from 'components/pages/welcome/assets_temp/connection_illustration.svg';
import billingIllustration from 'components/pages/welcome/assets_temp/billing_illustration.svg';
import orderIllustration from 'components/pages/welcome/assets_temp/order_illustration.svg';
import PrizeItem from 'components/pages/welcome/components/PrizeItem';
import { BsFillBasket3Fill, BsInfoCircle } from 'react-icons/bs';
import { Button } from 'primereact/button';
import styles from './Welcome.module.scss';
import giftIllustration from './assets_temp/gift_illustration.svg';
import img from './assets_temp/tshirt.png';
import ProductCarousel from './components/ProductCarousel/ProductCarousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import PrButton from '../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { MdDone } from 'react-icons/md';
import { GetAccountCompletePercentageRequest } from '../../../features/sellers/_common/get-account-complete-percentage/get-account-complete-percentage.request';
import sellerService from '../../../features/sellers/_common/seller.service';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { GetAccountCompletePercentageDto } from '../../../features/sellers/_common/get-account-complete-percentage/dtos/get-account-complete-percentage.dto';
import informationsService from '../../../features/informations/informations.service';
import { DashboardInformations } from '../../../features/informations/get-dashboard-informations/get-dashboard-informations.response';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import { Menu } from '@headlessui/react';
import { BiDotsHorizontal } from 'react-icons/bi';
import { FaDollarSign, FaWallet } from 'react-icons/fa';
import { CiWallet } from 'react-icons/ci';
import BalanceDropdown from './components/BalanceDropdown/BalanceDropdown';
import { useNavigate } from 'react-router';

const WelcomePage = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const navigate = useNavigate();

	const [percentageLoading, setPercentageInfoLoading] = useState(true);
	const [infoLoading, setInfoLoading] = useState(true);
	const [sellerPercentage, setSellerPercentage] = useState<GetAccountCompletePercentageDto | undefined>(undefined);
	const [dashboardInfo, setDashboardInfo] = useState<DashboardInformations | undefined>(undefined);

	useEffect(() => {
		getSellerPercentage();
		getInfo();
	}, []);

	const getSellerPercentage = async () => {
		try {
			setPercentageInfoLoading(true);
			if (!user || !user.roles.isSeller) throw '';

			const request = new GetAccountCompletePercentageRequest(user.userId);
			const response = await sellerService.getAccountCompletePercentage(request);
			if (!response || !response.isSuccess || !response.data) throw '';

			const responseData = response.data.at(0);
			if (!responseData) throw '';

			setSellerPercentage(responseData);
		} catch (error) {
			console.log(error);
		} finally {
			setPercentageInfoLoading(false);
		}
	};

	const getInfo = async () => {
		try {
			setInfoLoading(true);
			if (!user || !user.roles.isSeller) throw '';

			const response = await informationsService.getDashboardInformations();
			if (!response || !response.isSuccess || !response.data) throw '';

			if (!response.isSuccess || !response.data) throw '';

			setDashboardInfo(response.data);
		} catch (error) {
			console.log(error);
		} finally {
			setInfoLoading(false);
		}
	};

	const skeleton = () => (
		<React.Fragment>
			<Skeleton className="mb-3" width="100%" height="300px" />
			<div className="flex flex-row justify-content-center align-items-center mb-3">
				<Skeleton className="flex-1 mr-2" height="150px" />
				<Skeleton className="flex-1 mr-2" height="150px" />
				<Skeleton className="flex-1" height="150px" />
			</div>
			<Skeleton width="100%" height="350px" />
		</React.Fragment>
	);

	return (
		<div className="container relative">
			<h3 className="mt-2">Welcome to your Printram dashboard. Let’s make some money today</h3>

			{!infoLoading && !percentageLoading ? (
				<React.Fragment>
					{(!sellerPercentage?.isAddedAConnectionTheStore || !sellerPercentage?.isAddedAPaymentConnection || !sellerPercentage?.isSellerHaveAnyOrder) && (
						<div className={`${styles.prizes} hidden md:flex bg-white border-round-md p-5`}>
							<div className={`flex flex-row justify-content-between`}>
								<div className={`${styles.imageSide} hidden xl:block`}>
									<h4 className="mb-auto">
										Connect your store to Etsy and <span style={{ color: '#4541FF' }}>get a $50</span>!
									</h4>
									<div className="w-full h-2rem"></div>
									<div className={styles.image} />
								</div>

								<div className={`grid`}>
									<PrizeItem completed={sellerPercentage?.isAddedAConnectionTheStore || false} step={'1'} title="Connect your store" text="You’ll easily connect your ecommerce platform to Printram in only a few clicks." action={() => navigate('/account/my-stores')} actionText="Connect" image={connectionIllustration} className={`${styles.prizeCard} col`} />
									<PrizeItem completed={sellerPercentage?.isAddedAPaymentConnection || false} step={'2'} title="Set up billing" text="You’ll input your billing information to cover the fulfilment fee each time you sell a product." action={() => navigate('/account/payments')} actionText="Setup" image={billingIllustration} className={`${styles.prizeCard} col`} />
									<PrizeItem completed={sellerPercentage?.isSellerHaveAnyOrder || false} step={'3'} title="Order Sample" text="Create your first order and start spending a $50!" action={() => navigate('/create-order')} actionText="Start" image={orderIllustration} className={`${styles.prizeCard} col`} />
								</div>
							</div>
						</div>
					)}

					{(!sellerPercentage?.isAddedAConnectionTheStore || !sellerPercentage?.isAddedAPaymentConnection || !sellerPercentage?.isSellerHaveAnyOrder) && (
						<div className={'flex md:hidden flex-column justify-content-center align-items-center'}>
							{!percentageLoading ? (
								<React.Fragment>
									<div className={'flex flex-column justify-content-center align-items-center my-2'}>
										<h5 className="mb-auto text-center">
											Connect your store to Etsy and <span style={{ color: '#4541FF' }}>get a $50</span>!
										</h5>
										<img src={giftIllustration} alt="prize" />
									</div>
									<div className={'bg-white w-screen py-2'}>
										<Swiper className={'pb-5 mx-3'} slidesPerView={1} spaceBetween={10} pagination={true} modules={[Pagination]}>
											<SwiperSlide>
												<div
													style={{
														backgroundColor: sellerPercentage?.isAddedAConnectionTheStore ? '#EBF0FF' : 'transparent',
														border: '1px solid #D1D3D4FF'
													}}
													className={'flex flex-column justify-content-center align-items-start p-2 border-round-sm'}>
													<img className={'mx-auto'} src={connectionIllustration} alt={'1'} />
													<p className={'mb-2 m-0 text-sm font-semibold'}>Step {'1'}</p>
													<h5 className={'mb-4 m-0 font-semibold'}>Connect your store</h5>
													<p className={'mb-5 m-0 text-sm'}>You’ll easily connect your ecommerce platform to Printram in only a few clicks.</p>
													{sellerPercentage?.isAddedAConnectionTheStore ? (
														<PrButton className="justify-content-center mt-auto w-full border-round-lg" text="Connect" onClick={() => navigate('/account/my-stores')} />
													) : (
														<div className="flex flex-row justify-content-start align-items-center mt-auto">
															<div className="flex justify-content-center border-circle p-2" style={{ backgroundColor: '#4541FF' }}>
																<MdDone size="24px" color="white" />
															</div>
															<div className="ml-2 font-semibold">Completed</div>
														</div>
													)}
												</div>
											</SwiperSlide>
											<SwiperSlide>
												<div
													style={{
														backgroundColor: sellerPercentage!.isAddedAPaymentConnection ? '#EBF0FF' : 'transparent',
														border: '1px solid #D1D3D4FF'
													}}
													className={'flex flex-column justify-content-center align-items-start p-2 border-round-md'}>
													<img className={'mx-auto'} src={connectionIllustration} alt={'1'} />
													<p className={'mb-2 m-0 text-sm font-semibold'}>Step {'1'}</p>
													<h5 className={'mb-4 m-0 font-semibold'}>Connect your store</h5>
													<p className={'mb-5 m-0 text-sm'}>You’ll easily connect your ecommerce platform to Printram in only a few clicks.</p>
													{sellerPercentage?.isAddedAPaymentConnection ? (
														<PrButton className="justify-content-center mt-auto w-full border-round-lg" text="Connect" onClick={() => navigate('/account/payments')} />
													) : (
														<div className="flex flex-row justify-content-start align-items-center mt-auto">
															<div className="flex justify-content-center border-circle p-2" style={{ backgroundColor: '#4541FF' }}>
																<MdDone size="24px" color="white" />
															</div>
															<div className="ml-2 font-semibold">Completed</div>
														</div>
													)}
												</div>
											</SwiperSlide>
											<SwiperSlide>
												<div
													style={{
														backgroundColor: sellerPercentage?.isSellerHaveAnyOrder ? '#EBF0FF' : 'transparent',
														border: '1px solid #D1D3D4FF'
													}}
													className={'flex flex-column justify-content-center align-items-start p-2 border-round-md'}>
													<img className={'mx-auto'} src={connectionIllustration} alt={'1'} />
													<p className={'mb-2 m-0 text-sm font-semibold'}>Step {'1'}</p>
													<h5 className={'mb-4 m-0 font-semibold'}>Connect your store</h5>
													<p className={'mb-5 m-0 text-sm'}>You’ll easily connect your ecommerce platform to Printram in only a few clicks.</p>
													{sellerPercentage?.isSellerHaveAnyOrder ? (
														<PrButton className="justify-content-center mt-auto w-full border-round-lg" text="Connect" onClick={() => navigate('/create-order')} />
													) : (
														<div className="flex flex-row justify-content-start align-items-center mt-auto">
															<div className="flex justify-content-center border-circle p-2" style={{ backgroundColor: '#4541FF' }}>
																<MdDone size="24px" color="white" />
															</div>
															<div className="ml-2 font-semibold">Completed</div>
														</div>
													)}
												</div>
											</SwiperSlide>
										</Swiper>
									</div>
								</React.Fragment>
							) : (
								<ProgressSpinner />
							)}
						</div>
					)}

					<div className="grid my-2 hidden md:flex">
						{!infoLoading ? (
							<React.Fragment>
								<div className="col-4">
									<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
										<div className="inline-flex justify-content-center align-items-center w-full">
											<BsFillBasket3Fill className="mr-3" size="1.2rem" />
											<p className="m-0 mr-auto font-bold">Orders requiring action</p>
											<Tooltip content="The number of orders waiting to be created." target=".tooltip_1" />
											<BsInfoCircle className="tooltip_1" size="1rem" />
										</div>
										<hr className="border-solid border-1 border-gray-300 w-full" />
										<div className="text-5xl font-bold m-0 mb-5">{dashboardInfo?.requireActionOrders}</div>
										<div className="h-1rem">
											<Button label="Go to orders" className={'p-button-outlined px-3 py-1 text-black-alpha-90 text-sm'} onClick={() => navigate('/product/seller/orders?status=2')} />
										</div>
									</div>
								</div>
								<div className="col-4">
									<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
										<div className="inline-flex justify-content-center align-items-center w-full">
											<FaDollarSign className="mr-3" size="1.2rem" />
											<p className="m-0 mr-auto font-bold">Daily cost</p>
											<Tooltip content="The total daily cost of Printram orders made by your store. This data is refreshed daily." target=".tooltip_2" />
											<BsInfoCircle className="tooltip_2" size="1rem" />
										</div>
										<hr className="border-solid border-1 border-gray-300 w-full" />
										<div className="text-5xl font-bold m-0 mb-5">
											$
											{dashboardInfo?.onDateCosts.formattedPricePerUnit
												.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										</div>
										<div className="h-1rem">
											<Button label="Go to orders" className={'p-button-outlined px-3 py-1 text-black-alpha-90 text-sm hidden'} />
										</div>
									</div>
								</div>
								<div className="col-4">
									<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
										<div className="inline-flex justify-content-center align-items-center w-full">
											<FaWallet className="mr-3" size="1.2rem" />
											<p className="m-0 mr-auto font-bold">Current balance</p>
											<BalanceDropdown />
										</div>
										<hr className="border-solid border-1 border-gray-300 w-full" />
										<div className="text-5xl font-bold m-0 mb-5">
											$
											{dashboardInfo?.balance.formattedPricePerUnit
												.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										</div>
										<div className="h-1rem">
											<Button label="Go to orders" className={'p-button-outlined px-3 py-1 text-black-alpha-90 text-sm hidden'} />
										</div>
									</div>
								</div>
							</React.Fragment>
						) : (
							<ProgressSpinner />
						)}
					</div>

					<div className={'flex md:hidden my-5'}>
						{!infoLoading ? (
							<Swiper slidesPerView={1.25} spaceBetween={20}>
								<SwiperSlide>
									<div className={``}>
										<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
											<div className="inline-flex justify-content-center align-items-center w-full">
												<BsFillBasket3Fill className="mr-3" size="1.2rem" />
												<p className="m-0 mr-auto font-bold">Orders requiring action</p>
												<Tooltip content="The number of orders waiting to be created." target=".tooltip_1" />
												<BsInfoCircle className="tooltip_1" size="1rem" />
											</div>
											<hr className="border-solid border-1 border-gray-300 w-full" />
											<div className="text-5xl font-bold m-0 mb-5">{dashboardInfo?.requireActionOrders}</div>
											<div className="h-1rem">{<Button label="Go to orders" className={'p-button-outlined px-3 py-1 text-black-alpha-90 text-sm'} onClick={() => navigate('/product/seller/orders?status=2')} />}</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={``}>
										<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
											<div className="inline-flex justify-content-center align-items-center w-full">
												<FaDollarSign className="mr-3" size="1.2rem" />
												<p className="m-0 mr-auto font-bold">Cost to date</p>
												<Tooltip content="The total daily cost of Printram orders made by your store. This data is refreshed daily." target=".tooltip_2" />
												<BsInfoCircle className="tooltip_2" size="1rem" />
											</div>
											<hr className="border-solid border-1 border-gray-300 w-full" />
											<div className="text-5xl font-bold m-0 mb-5">${dashboardInfo?.onDateCosts.formattedPricePerUnit}</div>
											<div className="h-1rem">{<Button label="Go to orders" className={'p-button-outlined px-3 py-1 text-black-alpha-90 text-sm'} />}</div>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className={``}>
										<div className="flex flex-column justify-content-center align-items-start bg-white border-round-md p-4">
											<div className="inline-flex justify-content-center align-items-center w-full">
												<FaWallet className="mr-3" size="1.2rem" />
												<p className="m-0 mr-auto font-bold">Current balance</p>
												<BalanceDropdown />
											</div>
											<hr className="border-solid border-1 border-gray-300 w-full" />
											<div className="text-5xl font-bold m-0 mb-5">${dashboardInfo?.balance.formattedPricePerUnit.toFixed(2)}</div>
											<div className="h-1rem"></div>
										</div>
									</div>
								</SwiperSlide>
							</Swiper>
						) : (
							<ProgressSpinner />
						)}
					</div>

					<div className={'border-round-lg bg-white p-3'}>
						{!infoLoading ? (
							<React.Fragment>
								<h5>Best Price with Printram</h5>
								<ProductCarousel loading={infoLoading} items={dashboardInfo!.bestSellerProducts} />
							</React.Fragment>
						) : (
							<ProgressSpinner
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)'
								}}
							/>
						)}
					</div>
				</React.Fragment>
			) : (
				skeleton()
			)}
		</div>
	);
};

export default WelcomePage;

import React, { useContext, useState } from 'react';
import { CreateUpgradeShippingContext, UpgradeShippingContextType } from '../context/UpgradeShippingContextProvider';
import ShippingInfoSection from '../components/shipping-info-section/ShippingInfoSection';
import PaymentMethods from '../components/payment-methods/PaymentMethods';
import CostBreakdown from '../components/cost-breakdown/CostBreakdown';
import styles from '../../orders/create-order/steps/review-order/StepReviewOrder.module.scss';
import PrButton from '../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { ShippingRateWithDiscountAmountDto, UpdateTransportRequest } from '../../../../features/orders/derived-features/order-transports/update-transport/update-transport.request';
import { orderTransportService } from '../../../../features/orders/derived-features/order-transports/order-transport.service';
import FullScreenLoader from '../components/full-screen-loader/FullScreenLoader';
import { useNavigate } from 'react-router';

const Checkout = () => {
	const context = useContext(CreateUpgradeShippingContext) as UpgradeShippingContextType;

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const completeOrder = async () => {
		try {
			setLoading(true);
			const request = new UpdateTransportRequest({
				orderId: context?.order!.id!,
				shipTo: context?.deliveryAddress!,
				shippingRate: new ShippingRateWithDiscountAmountDto({ shippingRate: context.selectedRate! }),
				paymentMethodType: context?.paymentMethodType!
			});

			const response = await orderTransportService.updateTransport(request);

			if (!response.isSuccess) throw '';

			navigate(`/product/seller/order/${context.order?.id}`);
			setLoading(false);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<React.Fragment>
			<ShippingInfoSection />

			<PaymentMethods />

			<section className="container-body p-3 z-1">
				<CostBreakdown />

				<div className={styles.payButton}>
					<PrButton text="Pay securely now" onClick={() => completeOrder()} icon={<BsFillShieldLockFill />} btnType="button" />
					<div className={styles.payButtonDescription}>
						<div>By completing this order,</div>
						<div>I agree to Printram's Terms of Service.</div>
					</div>
				</div>
			</section>

			{loading && <FullScreenLoader />}
		</React.Fragment>
	);
};

export default Checkout;

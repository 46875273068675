import HttpBaseService from 'services/_common/http-base-service';
import { GetShippoShipmentsRequest } from './get-shippo-shipments/get-shippo-shipments.request';
import { GetShippoShipmentsResponse } from './get-shippo-shipments/get-shippo-shipments.response';
import { GetShippingRatesRequest } from './get-shipping-rates/get-shipping-rates.request';
import { GetShippingRatesResponse } from './get-shipping-rates/get-shipping-rates.response';
import { ValidateAddressRequest } from './validate-address/validate-address-request';
import { ValidateAddressResponse } from './validate-address/validate-address-response';
import { ParseAddressRequest } from './parse-address/parse-address.request';
import { ParseAddressResponse } from './parse-address/parse-address.response';
import { UpdateTransportRequest } from './update-transport/update-transport.request';
import { UpdateTransportResponse } from './update-transport/update-transport.response';
import {
	GetShippingRatesForUpgradeRequest
} from './get-shipping-rates-for-upgrade/get-shipping-rates-for-upgrade.request';

class OrderTransportService extends HttpBaseService {
	constructor() {
		super('order-transports');
	}

	async getShippoShipments(request: GetShippoShipmentsRequest): Promise<GetShippoShipmentsResponse> {
		return await this.postData(request.getConvertUriExtension);
	}

	async getShippingRates(request: GetShippingRatesRequest): Promise<GetShippingRatesResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async validateAddress(request: ValidateAddressRequest): Promise<ValidateAddressResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async updateTransport(request: UpdateTransportRequest): Promise<UpdateTransportResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getShippingRatesForUpgrade(request: GetShippingRatesForUpgradeRequest): Promise<GetShippingRatesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async parseAddress(request: ParseAddressRequest): Promise<ParseAddressResponse> {
		return await this.getData(request.getConvertUriExtension);
	}
}

export const orderTransportService = new OrderTransportService();
